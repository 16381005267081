module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZCGJHXG","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicons/favicon-32x32.png","theme_color":"#000000","background_color":"#ffffff","display":"standalone","icons":[{"src":"src/assets/favicons/favicon-16x16.ico","sizes":"16x16","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-32x32.ico","sizes":"32x32","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-96x96.ico","sizes":"96x96","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-16x16.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicons/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicons/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e2365db877e2af5f956b570de247182"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
